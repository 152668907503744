import React, {createContext, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {host} from '../../../backend';
//import 'frontend/src/styles/fontawesome/pro/css/all.css';
import '../../../../styles/fontawesome/css/all.css';
export const DashboardContext = createContext();

export const DashboardProvider = ({children}) => {
  const cardApiEndpoints = [
    null,
    '/docvencidos',
    '/actividades/instanciasatrasadas',
    '/requisitosLegalesSitios/instanciasatrasadas',
    '/objetivos/objetivosatrasados',
    //'/capacitaciones/instanciasatrasadas',
    '/noConformidades/getncvencidas',
    '/indicadores/alertas-activas',
    '/getincompletemediciones',
    null
  ];
  const [cardData, setCardData] = useState([]);
  const [nombre, setNombre] = useState('');
  const [siteType, setSiteType] = useState(null);
  const [badgeCounts, setBadgeCounts] = useState(() => {
    // Try to get cached badge counts from localStorage
    const cachedCounts = localStorage.getItem('badgeCounts');
    return cachedCounts ? JSON.parse(cachedCounts) : Array(cardApiEndpoints.length).fill({red: 0, yellow: 0});
  });
  const authUser = JSON.parse(localStorage.getItem('auth_user'));
  const headers = {
    Authorization: localStorage.getItem('jwt_token'),
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const fetchBadgeCounts = async () => {
    // Initialize an array with default count objects
    const initialCounts = Array(cardApiEndpoints.length).fill({
      red: 0,
      yellow: 0
    });

    const fetchedCounts = await Promise.all(
      cardApiEndpoints.map((endpoint, index) => {
        if (endpoint === null) {
          return Promise.resolve(initialCounts[index]); // Return default count for null endpoints
        }

        const sede = localStorage.getItem('sede'); // Assuming you store sede_id in local storage

        return fetch(host + endpoint, {
          method: 'POST',
          headers: {
            Authorization: localStorage.getItem('jwt_token'),
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({sede_id: sede}) // Passing sede_id in request body
        })
          .then(res => res.json())
          .then(data => {
            return data; // assuming data has a 'red' and 'yellow' property for counts
          })
          .catch(err => {
            console.error(err);
            return initialCounts[index]; // Return default count on error
          });
      })
    );

    setBadgeCounts(fetchedCounts);
    localStorage.setItem('badgeCounts', JSON.stringify(fetchedCounts));
  };

  useEffect(() => {
    fetchBadgeCounts();
  }, []);
  const fetchSiteType = async () => {
    try {
      const response = await axios.post(
        host + '/onlysede',
        {
          sede: Number(localStorage.getItem('sede'))
        },
        {headers}
      );

      if (response.data.resp && response.data.resp.length > 0) {
        setSiteType(response.data.resp[0].tipo);
      } else {
        console.warn("Response 'resp' array is empty or undefined:", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (authUser && authUser.tipo !== 0) {
      if (authUser.tipo !== 0) {
        let isMounted = true;
        const fetchCardData = async () => {
          try {
            const sedeId = Number(localStorage.getItem('sede'));
            const organizacionId = localStorage.getItem('organizacion');

            const [sedeResponse, itemsResponse, powerBiResponse] = await Promise.all([
              axios.post(host + '/onlysede', {sede: sedeId}, {headers}),
              axios.post(host + '/items', {sedeid: sedeId}, {headers}),
              axios.get(`${host}/powerbi/reports`, {
                params: {sedeId, organizacionId},
                headers
              })
            ]);
            const nombre = sedeResponse.data.resp && sedeResponse.data.resp.length > 0 ? sedeResponse.data.resp[0].nombre : '';

            if (itemsResponse.data.items && isMounted) {
              const cardList = itemsResponse.data.items.map(item => ({
                id: item.id,
                subtitle: item.nombre,
                icon: item.icon
              }));
              const powerBiEnabled = powerBiResponse.data && powerBiResponse.data.length > 0;

              if (powerBiEnabled) {
                cardList.push({
                  id: 'powerbi', // Unique ID for the new card
                  subtitle: 'Reporteria',
                  icon: 'fa-thin fa-chart-mixed fa-3x' // Font Awesome icon classes
                });
              }

              setCardData(cardList);
              if (localStorage.getItem('selectedMenuIndex') == null) {
                localStorage.setItem('selectedMenuIndex', cardList[0].id);
              }
              setNombre(nombre);
            } else {
              console.error('itemsResponse.data.items is undefined:', itemsResponse.data);
            }
          } catch (error) {
            console.error(error);
          }
        };

        fetchSiteType();
        fetchCardData();

        return () => {
          isMounted = false;
        };
      }
    }
  }, []);

  return <DashboardContext.Provider value={{cardData, nombre, siteType, badgeCounts, fetchBadgeCounts}}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
