import {lazy} from 'react';

const ContactTable = lazy(() => import('./ContactTable'));

const ListaResponsables = lazy(() => import('./ListaResponsables'));

const ContactCard = lazy(() => import('./ContactCard'));

const ContactDetails = lazy(() => import('./ContactDetails'));

const Historial = lazy(() => import('./HistorialDoc'));

const Anexo = lazy(() => import('./AnexoDoc'));

const AnexoRef = lazy(() => import('./AnexoRef'));

const AnexoConf = lazy(() => import('./AnexoConf'));

const DocRef = lazy(() => import('./DocRef'));

const Historial2 = lazy(() => import('./HistorialRegistro'));

const Historial3 = lazy(() => import('./HistorialConf'));
const Historial3Corporativo = lazy(() => import('./HistorialConfCorporativo'));
const Responsables = lazy(() => import('./Responsables'));

const Usuarios = lazy(() => import('./Usuarios'));

const Organizaciones = lazy(() => import('./Organizaciones'));
const ListadoMaestro = lazy(() => import('./ListadoMaestroGlobal'));
const RequisitosLegalesGlobal = lazy(() => import('./RequisitosLegalesGlobal'));
const Sitios = lazy(() => import('./Sitios'));

const Sedes = lazy(() => import('./Sedes'));

const ListadoNC = lazy(() => import('./ListadoNC'));
const ListadoNCCorporativo = lazy(() => import('./ListadoNCCorporativo'));
const Permisos = lazy(() => import('./Permisos'));

const VerModificar = lazy(() => import('./VerModificar'));

const ListaSitiosGestores = lazy(() => import('./ListaSitiosGestores'));

const ListaSupergestores = lazy(() => import('./ListaSupergestores'));

const Test = lazy(() => import('./Test'));
const contactRoutes = [
  {
    path: '/historial1',
    component: Historial
  },
  {
    path: '/contact/table',
    component: ContactTable
  },
  {
    path: '/contact/list',
    component: ListaResponsables
  },
  {
    path: '/contact/card',
    component: ContactCard
  },
  {
    path: '/contact/details',
    component: ContactDetails
  },
  {
    path: '/responsables',
    component: Responsables
  },
  {
    path: '/historial2',
    component: Historial2
  },
  {
    path: '/historial3',
    component: Historial3
  },
  {
    path: '/historial3corporativo',
    component: Historial3Corporativo
  },
  {
    path: '/usuarios',
    component: Usuarios
  },
  {
    path: '/organizaciones',
    component: Organizaciones
  },
  {
    path: '/listadomaestro',
    component: ListadoMaestro
  },
  {
    path: '/requisitoslegalesglobal',
    component: RequisitosLegalesGlobal
  },
  {
    path: '/sitios',
    component: Sitios
  },

  {
    path: '/listasitiosgestores',
    component: ListaSitiosGestores
  },
  {
    path: '/listasupergestores',
    component: ListaSupergestores
  },
  {
    path: '/sedes',
    component: Sedes
  },
  {
    path: '/permisos',
    component: Permisos
  },
  {
    path: '/anexos',
    component: Anexo
  },
  {
    path: '/refs',
    component: DocRef
  },
  {
    path: '/anexoref',
    component: AnexoRef
  },
  {
    path: '/anexoconf',
    component: AnexoConf
  },
  {
    path: '/listadoNC',
    component: ListadoNC
  },
  {
    path: '/listadoNCCorporativo',
    component: ListadoNCCorporativo
  },
  {
    path: '/VerModificar',
    component: VerModificar
  },
  {
    path: '/test',
    component: Test
  }
];

export default contactRoutes;
