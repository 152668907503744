import React, {lazy} from 'react';
import {authRoles} from 'app/auth/authRoles';

const Dashboard2 = lazy(() => import('./dashboard2/Dashboard2'));

const dashboardRoutes = [
  {
    path: '/dashboard/',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/documentos',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/actividades',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/requisitoslegales',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/objetivosmetas',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/capacitaciones',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/conformidad',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/indicadores',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/medicion',
    component: Dashboard2,
    auth: authRoles.admin
  },
  {
    path: '/powerbi',
    component: Dashboard2,
    auth: authRoles.admin
  }
];

export default dashboardRoutes;
