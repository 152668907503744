import React, {Suspense} from 'react';
import '../styles/app/app.scss';

import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import AppContext from './appContext';
import history from '@history';

import routes from './RootRoutes';
import {Store} from './redux/Store';
import {renderRoutes} from 'react-router-config';
import Auth from './auth/Auth';
import RootRoutes from './RootRoutes';
import {Loading} from '@gull';
import {DashboardProvider} from './views/dashboard/dashboard2/DashboardProvider.jsx';
import {SupergestorDashboardProvider} from './views/dashboard/dashboard2/SupergestorDashboardProvider';
import {S} from 'react-ladda/dist/constants';
function App() {
  return (
    <AppContext.Provider value={{routes}}>
      <Provider store={Store}>
        <Auth>
          <Suspense fallback={<Loading></Loading>}>
            <DashboardProvider>
              <SupergestorDashboardProvider>
                <Router history={history}>{renderRoutes(RootRoutes)}</Router>
              </SupergestorDashboardProvider>
            </DashboardProvider>
          </Suspense>
        </Auth>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
