import React, {lazy} from 'react';
import {authRoles} from 'app/auth/authRoles';

const SupergestorDashboard = lazy(() => import('./dashboard2/SupergestorDashboard'));

const supergestorDashboardRoutes = [
  {
    path: '/supergestor/',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/documentos',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/actividades',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/requisitoslegales',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/objetivosmetas',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/noconformidades',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/capacitaciones',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/conformidad',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/indicadores',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/medicion',
    component: SupergestorDashboard,
    auth: authRoles.admin
  },
  {
    path: '/supergestor/powerbi',
    component: SupergestorDashboard,
    auth: authRoles.admin
  }
];

export default supergestorDashboardRoutes;
